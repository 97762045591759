import { DefaultTheme } from '@rsc/ui-components/theme';
import ChevronDownSD from '@rsc/ui-components/svgs/icon-arrow-down-sd.svg';
import styles from './utils/styles';
import themeColors from './themeColors';

import {
  StyledForm,
  FormRow,
  FormCheck,
  ValidationMessage,
} from './StyledForm';

import logo from '../images/sd-logo-white-text.svg';
import mobileLogo from '../images/logoMobile.svg';
import sdOrangeStripe from '../images/SD_Orange_Stripe.jpg';

// use shared template and extend/override with custom theme values

const theme = {
  ...DefaultTheme,
  button: {
    ...DefaultTheme.button,
    borderRadius: '0.375rem',
    borderRadiusInner: '0.25rem',
    borderStyleInner: 'dashed',
    borderStyleInnerHover: 'solid',
    borderWidthInner: '2px',
    padding: '0.25rem',
    paddingInner: '0.625rem 1rem',
    textTransform: 'uppercase',
    primary: {
      ...DefaultTheme.button.primary,
      background: styles.colors.orange[600],
      backgroundActive: styles.colors.orange[800],
      backgroundHover: styles.colors.orange[600],
      backgroundDisabled: styles.colors.orange[600],
      borderColor: styles.colors.orange[600],
      borderColorInner: 'rgba(255, 255, 255, 0.75)',
      borderColorInnerHover: styles.colors.white,
    },
    secondary: {
      ...DefaultTheme.button.secondary,
      background: styles.colors.white,
      backgroundActive: styles.colors.gray[100],
      backgroundHover: styles.colors.white,
      backgroundDisabled: styles.colors.gray[200],
      borderColor: styles.colors.white,
      borderColorInner: styles.colors.orange[600],
      borderColorInnerHover: styles.colors.orange[800],
      textColor: styles.colors.orange[600],
      textColorHover: styles.colors.orange[800],
    },
    tertiary: {
      ...DefaultTheme.button.tertiary,
      background: styles.colors.gray[600],
      backgroundActive: styles.colors.gray[500],
      backgroundHover: styles.colors.gray[800],
      backgroundDisabled: styles.colors.gray[600],
      borderColor: styles.colors.gray[600],
      borderColorInner: 'rgba(255, 255, 255, 0.75)',
      borderColorInnerHover: styles.colors.white,
    },
  },
  colors: {
    ...DefaultTheme.colors,
    ...themeColors,
  },
  forms: {
    contact: {
      formCheck: FormCheck,
      formRow: FormRow,
      styledForm: StyledForm,
      validationMessage: ValidationMessage,
    },
  },
  hero: {
    fontColor: 'white',
    span: {
      textTransform: 'uppercase',
    },
  },
  images: {
    logo,
    mobileLogo,
  },
  name: 'SportDOG Theme',
  navigation: {
    mainItemTextTransform: 'uppercase',
    subSectionHeadingTextTransform: 'uppercase',
    fontWeight: 700,
    downArrowSize: 12,
    downArrowSpacing: '0.375rem',
    DownArrow: ChevronDownSD,
    NavDesktopMenuItemFontSize: '1rem',
  },
  notificationBanner: {
    background: `${styles.colors.orange[500]} url(${sdOrangeStripe}) top left repeat`,
  },
  productCard: {
    ...DefaultTheme.productCard,
    backgroundColor: styles.colors.gray[800],
    borderColorHover: '#4c4c4e',
    boxShadowHover: 'none',
    titleColor: 'white',
    titleColorHover: styles.colors.orange[500],
    titleFontWeight: 'bold',
    titleTextTransform: 'uppercase',
  },
  quizCard: {
    recommendationBackgroundColor: styles.colors.gray[800],
  },
};

export default theme;
