import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import { SiteSettingsProvider } from '@rsc/contexts/SiteSettingsContext';
import { CartProvider } from '@rsc/contexts/CartContext';
import CustomerContextProvider from '@rsc/contexts/CustomerContext';
import useSiteSettings from '@components/hooks/useSiteSettings';

import theme from './src/components/theme';

function RootWrapper({ element }: { element: React.ReactElement }) {
  return (
    <ThemeProvider theme={theme}>
      <SiteSettingsProvider useSiteSettings={useSiteSettings}>
        <CustomerContextProvider>
          <CartProvider>{element}</CartProvider>
        </CustomerContextProvider>
      </SiteSettingsProvider>
    </ThemeProvider>
  );
}

export default RootWrapper;
