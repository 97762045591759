const styles = {
  colors: {
    gray: {
      100: '#EEEEEE',
      200: '#D4D4D4',
      300: '#C4C4C4',
      400: '#A29E9D',
      500: '#646464',
      600: '#4c4e4e',
      700: '#333333',
      800: '#2e2e2e',
      900: '#1B1B1B',
    },
    orange: {
      100: '#FEECDD',
      500: '#F58026',
      600: '#f06f0b',
      700: '#d8640a',
      800: '#d96206',
    },
    white: '#ffffff',
    black: '#000000',
    red: {
      100: '#ffdddd',
      300: '#ff5d5d',
      500: '#d91a00',
    },
    green: {
      100: '#D5FFD8',
      500: '#008A06',
    },
    blue: {
      500: '#0f75bd',
    },
  },
  boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.25)',
};

export default styles;
